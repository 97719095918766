/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

function Icons() {
  return (
    <div className="icons">
      <a href="https://github.com/chyke007" target="_blank">
        <i className="fa fa-github fa-2x"></i>
      </a>
      <a
        href="https://linkedin.com/in/chibuike-nwachukwu-29a7a0111"
        target="_blank"
      >
        <i className="fa fa-linkedin fa-2x"></i>
      </a>
      <a
        href="https://youtube.com/channel/UCkMFm0MR4TuOFGr-9_HSLpA"
        target="_blank"
      >
        <i className="fa fa-youtube fa-2x"></i>
      </a>
      <a href="https://nwachukwuchibuike.medium.com" target="_blank">
        <span className="text-link">ME</span>
      </a>
      <a
        href="https://stackoverflow.com/users/10748125/chyke007"
        target="_blank"
      >
        <i className="fa fa-stack-overflow fa-2x"></i>
      </a>
      <a href="https://dev.to/chyke007" target="_blank">
        <span className="text-link">DV</span>
      </a>
    </div>
  )
}

export default Icons
