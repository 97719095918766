import React from "react"
// import { Link } from "gatsby"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Icons from "components/icons"

export const query = graphql`
  query {
    allFile(
      filter: { sourceInstanceName: { eq: "content" }, name: { eq: "index" } }
    ) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              lhp
              lhs
              intro
            }
          }
        }
      }
    }
  }
`

const IndexPage = props => {
  const data = props.data.allFile.edges[0].node.childMarkdownRemark.frontmatter

  return (
    <div className="body-home" id="bg-img">
      <Layout link="home">
        <SEO
          title="Home"
          description="Nwachukwu Chibuike personal portfolio website"
          keywords="Nwachukwu chibuike, Chibuike, Nwachukwu, Genesys tech hub product,Web developer nigeria, VueJs, Lagos, FUTO"
        />

        {/* <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    */}
        <main id="home">
          <h1 className="lg-heading">
            {data.lhp} &nbsp;
            <span className="text-secondary">{data.lhs}</span>
          </h1>
          <h2 className="sm-heading">{data.intro}</h2>
          <Icons />
        </main>
      </Layout>
    </div>
  )
}

export default IndexPage
